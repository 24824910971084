<template>
  <b-container>
    <div v-if="tasks !== null || tasks.length !== 0">
      <OverviewTable :groupList="groupList" :taskList="tasks" :imageAktuell="imageAktuell" :pcHostname="pcHostname"
                     @delete-task="deleteTask"/>
      <div class="row pr-2">
        <div class="mx-auto">
          <b-button class="mt-3 text-bold" :variant="variantSuccess" @click="sendTasks">
            <b-spinner v-show="loadingSendTasks" small/>
            <span v-if="groupList" class="text-bold">Hinzufügen</span>
            <span v-else class="text-bold">Abschicken</span>
          </b-button>
          <b-button class="mt-3 ml-2 text-bold" variant="primary" @click="changeState('start')">
            <span class="text-bold">Neue Aktion</span>
          </b-button>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import {mapGetters} from "vuex";
import {putRequest} from "@/modules/requests";
import OverviewTable from "@/components/lists/computerList/tasks/createTaskModal/OverviewTable.vue";

export default {
  name: "Finish",
  props: ['taskList', 'groupList', 'pcHostname', 'imageAktuell'],
  components: {
    OverviewTable
  },
  data() {
    return {
      loadingSendTasks: false,
      tasks: this.taskList
    }
  },
  watch: {
    taskList: function() {
      this.tasks = this.taskList
    }
  },
  methods: {
    changeState(data) {
      this.$emit('change-state', data)
    },
    deleteTask(task) {
      this.$emit('delete-task', task)
    },
    async sendTasks() {
      this.loadingSendTasks = true;
      if(this.groupList) {
        this.$emit('group-tasks', this.tasks)
        this.loadingSendTasks = false;
      }
      else {
        await putRequest('tasks/add', this.tasks, this, 'Aktionen erfolgreich hinzugefügt!')
            .then(() => {
              this.$emit('tasks-added', { failed: false })
              this.loadingSendTasks = false;
            })
            .catch(() => {
              this.$emit('tasks-added', { failed: true })
              this.loadingSendTasks = false;
            })
      }
    },
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
