<template>
  <b-table v-if="groupList" :fields="groupTable.fields" :items="taskList" striped small caption-top :class="tableClasses">
    <template #cell(name)="data">
      {{ data.item.displayname }}
    </template>
    <template #cell(install)="data">
      <div v-if="data.item.type === 'packet' || data.item.type === 'checklist'">
        {{ data.item.info }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(aktuell)="data">
      <div v-if="data.item.displayname === 'Imaging'">
        {{ imageAktuell }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(next)="data">
      <div v-if="data.item.displayname === 'Imaging'">
        {{ data.item.command_parameter }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(shutdown_time)="data">
      <div v-if="data.item.type === 'shutdown'">
        {{ data.item.command_parameter }}
        <span v-if="data.item.shutdown">Ausschalten</span>
        <span v-else-if="data.item.shutdown === false">Nicht ausschalten</span>
        <span v-else>Keine Änderung</span>
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(speed)="data">
      <div v-if="data.item.type === 'sync'">
        {{ data.item.syncSpeed / 1000 }} (KByte/s)
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(time)="data">
      <div>{{ data.item.time_execute_scheduled }}</div>
    </template>
    <template #cell(delete)="data">
      <b-button size="sm" :variant="variantDanger" v-bind:key="'del-btn-' + data.command_id" @click="deleteTask(data.item)">
        <b-icon-trash-fill/>
      </b-button>
    </template>
  </b-table>

  <b-table v-else :fields="table.fields" :items="taskList" striped small caption-top :class="tableClasses">
    <template #cell(name)="data">
      {{ data.item.displayname }}
    </template>
    <template #cell(install)="data">
      <div v-if="data.item.type === 'packet' || data.item.type === 'course'">
        {{ data.item.info }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(aktuell)="data">
      <div v-if="data.item.displayname === 'Hostname'">
        {{ pcHostname }}
      </div>
      <div v-else-if="data.item.displayname === 'Imaging'">
        {{ imageAktuell }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(next)="data">
      <div v-if="data.item.displayname === 'Hostname' || data.item.displayname === 'Imaging'">
        {{ data.item.command_parameter }}
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(speed)="data">
      <div v-if="data.item.type === 'sync'">
        {{ data.item.command_parameter / 1000 }} (KByte/s)
      </div>
      <div v-else>
        -
      </div>
    </template>
    <template #cell(time)="data">
      <div>{{ data.item.time_execute_scheduled }}</div>
    </template>
    <template #cell(delete)="data">
      <b-button size="sm" :variant="variantDanger" v-bind:key="'del-btn-' + data.command_id" @click="deleteTask(data.item)">
        <b-icon-trash-fill/>
      </b-button>
    </template>
  </b-table>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "OverviewTable",
  props: ['groupList', 'taskList', 'imageAktuell', 'pcHostname'],
  data() {
    return {
      table: {
        fields: [
          {key: 'name', label: 'Aktionen'},
          {key: 'install', label: 'Installieren/Deinstallieren'},
          {key: 'aktuell', label: 'Hostname/Image aktuell'},
          {key: 'next', label: 'Hostname/Image Zukunft'},
          {key: 'speed', label: 'Sync-Speed'},
          {key: 'time', label: 'Zeitpunkt'},
          {key: 'delete', label: 'Löschen'},
        ]
      },
      groupTable: {
        fields: [
          {key: 'name', label: 'Aktionen'},
          {key: 'install', label: 'Installieren/Deinstallieren'},
          {key: 'aktuell', label: 'Image aktuell'},
          {key: 'next', label: 'Image Zukunft'},
          {key: 'shutdown_time', label: 'Schaltzeit'},
          {key: 'speed', label: 'Sync-Speed'},
          {key: 'time', label: 'Zeitpunkt'},
          {key: 'delete', label: 'Löschen'},
        ]
      },
    }
  },
  methods: {
    deleteTask(task) {
      this.$emit('delete-task', task)
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    tableClasses() {
      if(this.userThemeId === 2) {
        return []
      }
      return ['text-white']
    },
      ...mapGetters(['userThemeId']),
      variantSuccess() {
        if(this.userThemeId === 3) { return 'success-deuteranopia' }
        return 'success'
      },
      variantDanger() {
        if(this.userThemeId === 3) { return 'danger-deuteranopia' }
        return 'danger'
    }
  },
  filters: {
    capitalize(task) {
      return task.charAt(0).toUpperCase() + task.slice(1);
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
