<template>
 <b-container>
   <b-row v-for="task in optionList" v-bind:key="task.text" class="mb-2 mt-3">
     <b-col cols="4" class="py-0">
       <div>Aktion: <span class="text-bold">{{ task.displayname }}</span></div>
     </b-col>
     <b-col v-if="state.type === 'packet'" cols="5">
       <b-form-radio-group v-if="task.commands.length < 2 && task.commands[0].type === 'install'"
                           v-model="task.switchbox" button-variant="outline-primary" buttons
                           :options="[{text: 'Installieren', value: true}]"/>
       <b-form-radio-group v-else-if="task.commands.length < 2 && task.commands[0].type === 'deinstall'"
                           v-model="task.switchbox" button-variant="outline-primary" buttons
                           :options="[{text: 'Deinstallieren', value: true}]"/>
       <b-form-radio-group v-else v-model="task.switchbox" button-variant="outline-primary" buttons
                           :options="radioOptions"/>
     </b-col>
     <b-col v-if="state.type === 'course'" cols="5">
       <b-row>
         <b-col>
           <b-form-radio-group v-model="switchBox" button-variant="outline-primary" buttons
                               :options="radioOptionsCourse"/>
         </b-col>
         <b-col>
           <span v-for="command in task.commands" v-bind:key="command">
            <b-badge class="mb-1 mr-2" variant="primary">{{ command }}</b-badge>
          </span>
         </b-col>
       </b-row>
     </b-col>
     <b-col cols="auto" class="ml-auto pr-0">
       <b-button variant="primary" class="ml-auto" :disabled="disabledButton || disabledSyncSpeedButton"
                 @click="emitTask(task)">
         Bestätigen
       </b-button>
     </b-col>
     <b-col cols="auto" class="pr-0">
       <b-button :variant="variantDanger" @click="deleteTask(task)">
         <b-icon-trash-fill/>
       </b-button>
     </b-col>
   </b-row>
   <div v-if="state.type === 'sync' || (state.type === 'course' && !switchBox)">
     <label class="mt-3">Geschwindigkeit:</label>
     <SyncSpeed class="mb-2 w-25" :syncSpeed="1000" @sync-speed-changed="syncSpeedChange"
                @sync-speed-invalid="syncSpeedCheck"/>
   </div>
   <TimeOption @new-time="newTime" @new-date="newDate" @date-time-check="datetimeCheck"/>
 </b-container>
</template>

<script>
import _ from "lodash";
import dayjs from "dayjs";
import {mapGetters} from "vuex";
import SyncSpeed from "@/components/lists/computerList/tasks/utility/SyncSpeed.vue";
import TimeOption from "@/components/lists/computerList/tasks/utility/dateTime/TimeOption.vue";

export default {
  name: "Option",
  props:['optionList', 'state', 'pcId'],
  components: {
    TimeOption,
    SyncSpeed
  },
  data() {
    return {
      time: dayjs().format('HH:mm'),
      date: dayjs().format('DD.MM.YYYY'),
      syncSpeed: null,
      disabledButton: false,
      disabledSyncSpeedButton: false,
      radioOptions: [
        {text: 'Installieren', value: true},
        {text: 'Deinstallieren', value: false}
      ],
      radioOptionsCourse: [
        {text: 'Pakete', value: true},
        {text: 'Syncs', value: false}
      ],
      switchBox: true
    }
  },
  watch: {
    switchBox() {
      this.disabledSyncSpeedButton = false
    }
  },
  methods: {
    emitTask(task) {
      let data = {
        'pc_id': this.pcId,
        'command_id': null,
        'sync_id': null,
        'command_parameter': null,
        'time_execute_scheduled': this.date + ' ' + this.time,
        'displayname': task.displayname,
        'type': null,
        'info': null
      }

      switch(this.state.type) {
        case 'sync':
          data.sync_id = task.id
          data.type = 'sync'
          data.command_parameter = this.syncSpeed
          break
        case 'course':
          data.command_id = task.id
          data.type = 'course'
          data.info = 'Kursweise Installieren'
          if(this.switchBox) {
            data.command_parameter = 'checklist_packets'
          }
          else {
            data.command_parameter = 'checklist_syncs'
            data.sync_speed = this.syncSpeed
          }
          break
        case 'packet':
          if(task.commands.length < 2) {
            data.command_id = task.commands[0].id
            data.info = task.commands[0].type === 'install' ? 'Installieren' : 'Deinstallieren'
          }
          else if(task.switchbox) {
            let x = _.find(task.commands, (entry) => {
              if(entry.type === 'install') { return true }
            })
            data.command_id = x.id
            data.info = 'Installieren'
          }
          else {
            let x = _.find(task.commands, (entry) => {
              if(entry.type === 'deinstall') { return true }
            })
            data.command_id = x.id
            data.info = 'Deinstallieren'
          }
          data.type = 'packet'
          break
        case 'command':
          data.command_id = task.id
      }

      this.$emit('add-new-task', data)
      this.deleteTask(task)
    },
    deleteTask(task) {
      this.$emit('remove-task', task)
    },
    newTime(data) {
      this.time = data
    },
    newDate(data) {
      this.date = data
    },
    datetimeCheck(data) {
      this.disabledButton = data
    },
    syncSpeedChange(data) {
      this.syncSpeed = data
    },
    syncSpeedCheck(data) {
      this.disabledSyncSpeedButton = data
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/styles/Modal';
</style>
